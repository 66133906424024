<template>
  <div>
    <SubheaderBlog />
    <main class="pad-t-3 pad-b-5 bg-blog">
      <div class="bb-container">
        <h1 class="header-2 mar-b-2">{{ categoryName }}</h1>
        <div class="g-post">
          <div class="g-post__items">
            <BlogPostThumb
              v-for="(post, index) in initialPosts"
              :key="index"
              :post="post"
              :class="{ 'thumb-post--land thumb-post--hero': index === 0 }"
              :show-excerpt="index === 0"
            />
          </div>
        </div>
      </div>
      <div class="pad-t-1 mar-b-0 bb-container">
        <CtaPost></CtaPost>
      </div>
    </main>
  </div>
</template>
<script>
import BlogPostThumb from "./components/BlogPostThumb.vue";
import SubheaderBlog from "./SubheaderBlog.vue";
import CtaPost from "../cta/CtaPost.vue";
import {mapGetters} from "vuex";

export default {
  name: "BlogCategory",
  components: {
    SubheaderBlog,
    BlogPostThumb,
    CtaPost,
  },
  metaInfo() {
    return {
      title: `${this.categoryName} | HelloBB`,
      meta: [
        { name: "description", content: this.description },
        //{ name: "referrer", content: "no-referrer-when-downgrade" },
        //{ property: "og:title", content: this.$route.params.slug + " - HelloBB Blog - Información para padres novatos" },
        //{ property: "og:site_name", content: "HelloBB Blog - Información para padres novatos" },
        //{ property: "og:type", content: "website" },
        //{ property: "og:image", content: "https://www.hellobb.net/hbb-card.jpg" },
        //{ property: "og:image:width", content: "1200" },
        //{ property: "og:image:height", content: "630" },
        //{ property: "og:description",content: "Guías, información sobre productos y trucos que harán tu camino a la maternidad más fácil." },
        //{ property: "og:url", content: "https://www.hellobb.net/blog/categorias/" + this.$route.params.slug },
        //{ property: "article:publisher", content: "https://www.facebook.com/holahelloBB" },
        //{ name: "twitter:card", content: "summary_large_image" },
        //{ name: "twitter:title", content: this.$route.params.slug + " - HelloBB Blog - Información para padres novatos" },
        //{ name: "twitter:description", content: "Guías, información sobre productos y trucos que harán tu camino a la maternidad más fácil." },
        //{ name: "twitter:image", content: "https://www.hellobb.net/hbb-card.jpg" },
        //{ name: "twitter:url", content: "https://www.hellobb.net/blog/categorias/" + this.$route.params.slug },
        //{ name: "twitter:label1", content: "Written by" },
        //{ name: "twitter:data1", content: "HelloBB" },
      ],
      link: [
        {
          rel: "canonical",
          href:
            "https://www.hellobb.net/blog/categorias/" +
            this.$route.params.slug,
        },
      ],
      script: [
        {
          type: "application/ld+json",
          json: {
            "@context": "https://schema.org",
            "@type": "WebSite",
            publisher: {
              "@type": "Organization",
              name: "HelloBB Blog - Información para padres novatos",
              url: "https://www.hellobb.net/",
              logo: {
                "@type": "ImageObject",
                url: "https://www.hellobb.net/logo-hellobb-dark.svg",
                width: 48,
                height: 48,
              },
            },
            url:
              "https://www.hellobb.net/blog/categorias/" +
              this.$route.params.slug,
            image: {
              "@type": "ImageObject",
              url: "https://www.hellobb.net/hbb-card.jpg",
              width: 7183,
              height: 2885,
            },
            mainEntityOfPage: {
              "@type": "WebPage",
              "@id": "https://www.hellobb.net/",
            },
            description:
              this.description,
          },
        },
      ],
    };
  },
  data() {
    return {
      loading: true,
      page: 2,
    };
  },
  computed: {
    ...mapGetters({
      initialPosts: "categoryPosts",
      tag: "tag"
    }),
    categoryName() {
      if (this.initialPosts.length > 0)
        return this.initialPosts[0].tags?.find(
          (tag) => tag.slug === this.$route.params.slug
        )?.name;
    },
    description() {
      return this.tag.description;
    }
  },
  async serverPrefetch() {
    await this.$store.dispatch("getCategoryPosts");
    await this.$store.dispatch("getTag", this.$route.params.slug);
  },
  // methods: {
  //   // async infiniteHandler($state) {
  //   //   try {
  //   //     const data = await this.api.posts.browse({
  //   //       filter: "tag:" + this.$route.params.slug,
  //   //       page: this.page,
  //   //       limit: 6,
  //   //       include: "tags",
  //   //       order: "created_at DESC"
  //   //     });
  //   //     if (data.length) {
  //   //       this.page += 1;
  //   //       let posts = [...this.initialPosts]
  //   //       posts.push(...data);
  //   //       this.$store.commit("setCategoryPosts", posts);
  //   //       this.loading = false;
  //   //       $state.loaded();
  //   //     } else {
  //   //       $state.complete();
  //   //     }
  //   //   } catch (error) {
  //   //     console.log(error);
  //   //   }
  //   // },
  // },
  // created() {
  //   this.api = new GhostContentAPI({
  //     url: process.env.BLOG_URL,
  //     key: process.env.BLOG_API_KEY,
  //     version: process.env.BLOG_API_VERSION,
  //   });
  // },
};
</script>
<style lang=""></style>
